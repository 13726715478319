import Api from '../../api';

class EnumsService {
  getEnums = async (data) => {
    try {
      const emums = Api.Enums.getEnums(data);
      return emums;
    } catch (e) {
      return {};
    }
  };
}

export default EnumsService;

