import {
  requiredField,
  requiredValue,
  requiredSelect,
  isNotNegativeNumber,
} from '../../../../bi/utils/checkValidation';
import {
  PLACING_SELECT,
  BREAKFAST_SELECT,
  NDS_SELECT,
  INVALID_CONTRACT,
} from '../../../constants/profile';

const validationObjHotelContractRate = {
  RateName: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  RoomTypeId: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  Description: {
    fn: requiredValue,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  Pax: {
    fn: value => requiredSelect(value, PLACING_SELECT),
    msg: INVALID_CONTRACT.SELECT_ON_LIST,
  },
  Breakfast: {
    fn: value => requiredSelect(value, BREAKFAST_SELECT),
    msg: INVALID_CONTRACT.SELECT_ON_LIST,
  },
  Price: {
    fn: isNotNegativeNumber,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  Vat: {
    fn: value => requiredSelect(value, NDS_SELECT),
    msg: INVALID_CONTRACT.SELECT_ON_LIST,
  },
  StartLifeTime: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  EndLifeTime: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  Images: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  Periods: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
};

export {
  validationObjHotelContractRate,
};
