const isPositiveNumber = (value) => {
  const num = Number(value);

  return (!isNaN(num) && num > 0);
};

const isPositiveNumberWithZero = (value) => {
  const num = Number(value);

  return (!isNaN(num) && (num > 0 || (num === 0 && value.length === 1)));
};

const TEXTS = {
  MSG: 'должно быть указано положительное целое число',
  MSGWITHZERO: 'должно быть указано положительное целое число или 0',
};

const validationObj = {
  AlertBalance: {
    fn: isPositiveNumber,
    msg: TEXTS.MSG,
  },
  Amount: {
    fn: isPositiveNumberWithZero,
    msg: TEXTS.MSGWITHZERO,
  },
};

class AccountingValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    let msg = '';

    if (!validator.fn(value)) {
      msg = validator.msg;
    }

    return msg;
  }
}

export default AccountingValidator;
