const isPositiveNumberAndNotGreaterTwelve = (value) => {
  const num = Number(value);

  return (!isNaN(num) && num >= 0 && num < 13);
};

const validationObj = {
  MonthAmt: {
    fn: isPositiveNumberAndNotGreaterTwelve,
    msg: 'должно быть указано положительное целое число не более 12 или 0',
  },
};

class DocumentsValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    let msg = '';

    if (!validator.fn(value)) {
      msg = validator.msg;
    }

    return msg;
  }
}

export default DocumentsValidator;
