import {
  requiredField,
  requiredSelect,
  requiredSelectIata,
  findAviaCost,
} from '../../../../bi/utils/checkValidation';
import { selectObj } from '../../../utils/air';

import {
  INVALID_CONTRACT,
  AVIA_PROGRAMM_CONTRACT,
} from '../../../constants/profile';

import { ONLY_NUMBER_AND_LETTERS, REG_AVIA_CONTRACT } from '../../../../bi/constants/regExp';

const formatTest = (field, value) => field.test(value);

const info = {
  0: {
    SU: value => formatTest(REG_AVIA_CONTRACT.MILE_AEROFLOT, value),
    U6: value => formatTest(REG_AVIA_CONTRACT.MILE_U6, value),
  },
  1: {
    SU: value => value.length && value.includes('/'),
    U6: value => formatTest(REG_AVIA_CONTRACT.SALE_U6, value),
    TK: value => formatTest(REG_AVIA_CONTRACT.SALE_TURKISH, value),
    UT: value => formatTest(REG_AVIA_CONTRACT.SALE_UT, value),
  },
  2: {
    S7: value => formatTest(REG_AVIA_CONTRACT.MILE_S7, value),
  },
  messages: {
    0: {
      SU: INVALID_CONTRACT.MILE_AEROFLOT,
      U6: INVALID_CONTRACT.MILE_U6,
    },
    1: {
      SU: INVALID_CONTRACT.SALE_AEROFLOT,
      U6: INVALID_CONTRACT.SALE_U6,
      TK: INVALID_CONTRACT.SALE_TURKISH,
      UT: INVALID_CONTRACT.SALE_UT,
    },
    2: {
      S7: INVALID_CONTRACT.MILE_S7,
    },
  },
};

const validationObj = {
  IsDiscount: {
    fn: value => requiredSelect(value.IsDiscount, AVIA_PROGRAMM_CONTRACT),
    msg: () => INVALID_CONTRACT.SELECT_ON_LIST,
  },
  Iata: {
    fn: value => requiredSelectIata(value.Iata, selectObj(value)),
    msg: () => INVALID_CONTRACT.SELECT_ON_LIST,
  },
  Number: {
    fn: ({ Number, IsDiscount }, contractIata) => (
      info[IsDiscount] && info[IsDiscount][contractIata]
      ? info[IsDiscount][contractIata](Number)
      : requiredField(formatTest(ONLY_NUMBER_AND_LETTERS, Number))
    ),
    msg: ({ IsDiscount }, contractIata) => (
      info.messages[IsDiscount] && info.messages[IsDiscount][contractIata]
      ? info.messages[IsDiscount][contractIata]
      : INVALID_CONTRACT.EMPTY),
  },
};

class AviaContractValidator {
  isValid(field, obj) {
    const part = field.split('.');
    let result = '';

    if (part.length === 1) {
      const contract = findAviaCost(obj, selectObj(obj));
      const contractIata = contract ? contract.iata : '';
      const validator = validationObj[field];
      const validationFn = validator.fn;

      if (!validationFn(obj, contractIata)) {
        result = validator.msg(obj, contractIata);
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(obj[field])) {
        result = validator.msg;
      }
    }

    return result;
  }
}

export default AviaContractValidator;
