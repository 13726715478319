import moment from 'moment';

const isNotNegativeNumber = (value) => {
  if (typeof value === 'string' && !value.length) return false;

  const num = Number(value);

  return (!isNaN(num) && num >= 0);
};

const isFilled = value => value.length;

const isSettedDate = value => moment.isMoment(value) && value.isValid();

const validationObj = {
  amount: {
    fn: isNotNegativeNumber,
    msg: 'должно быть указано неотрицательное число',
  },
  credit: {
    fn: isNotNegativeNumber,
    msg: 'должно быть указано неотрицательное число',
  },
  description: {
    fn: isFilled,
    msg: 'должно быть заполнено',
  },
  date: {
    fn: isSettedDate,
    msg: 'дата должна быть установлена',
  },
};

class FinanceValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    let msg = '';

    if (!validator.fn(value)) {
      msg = validator.msg;
    }

    return msg;
  }
}

export default FinanceValidator;
